@import "/src/styles/mixin";

.title {
  @include font30;
}

.editor {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shop {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: 48px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  &_info {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    min-width: 422px;

    @media screen and (max-width: 1023px) {
      max-width: 292px;
      min-width: 240px;
    }

    @media screen and (max-width: 767px) {
      max-width: 357px;
      order: 2;
    }

    @media screen and (max-width: 470px) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @media screen and (max-width: 767px) {
      row-gap: 32px;
    }

    @media screen and (max-width: 470px) {
      row-gap: 16px;
    }
  }

  &_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &_title {
    @include font16Bold;
    color: var(--grey-fourth);
  }

  &_subtitle {
    @include font14;
    color: var(--secondary-color);
  }

  &_schedule {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
  }

  &_button {
    @include font14;
    @include contentButton;
    color: var(--secondary-color);
    padding: 12px 42px;
    max-width: 256px;
  }

  &_reviews {
    position: relative;
  }
}

.shop_photo {
  margin-top: 61px;
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 100px;

  .shop_img_wrap {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .shop_img {
      width: 100%;
      height: auto;
    }
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  @media screen and (max-width: 767px) {
    margin: 48px 0;
  }
}

.map_wrap {
  width: 100%;
  max-width: 992px;
  height: 500px;
  z-index: 0;
}

.iframe {
  max-width: 760px;
  overflow: hidden;
  border-radius: var(--border-radius-small);
  padding: 0 0 !important;
}

.marker_wrap {
  position: relative;

  .custom_marker {
    position: absolute;
    z-index: 1;
    left: -10px;
    top: -24px;
  }
}

.custom_balloon {
  opacity: 1;
  position: absolute;
  border-radius: 14px;
  padding: 24px 16px;
  bottom: -24px;
  min-width: 273px;
  width: auto;
  max-width: 364px;
  min-height: 116px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgb(255, 255, 255);
  color: var(--secondary-color);
  transition: 0.25s ease;
  display: flex;
  align-items: flex-start;

  .balloon_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 364px;

    .name {
      @include font14Bold;
      margin-bottom: 6px;
    }

    .time {
      @include font14;
      color: var(--grey-color);
      margin: 4px 0;
    }

    .close {
      position: absolute;
      top: -18px;
      right: -6px;
      opacity: 0.5;
      transition: 0.25s ease;
      width: 18px;
      height: 18px;
      &:hover {
        opacity: 1;
      }

      @media screen and (max-width: 767px) {
        top: -2px;
      }
    }

    .button {
    }

    .link {
    }

    .choose {
      @include contentButton;
      @include font14;
      padding: 6px 12px;
      margin: 12px auto 0 0;
      background-color: var(--primary-color);
    }

    @media screen and (max-width: 767px) {
      width: 267px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 12px 12px;
    width: 267px;
    min-height: 140px;
  }
}
